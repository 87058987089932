import { Ability } from '@casl/ability'
import { isUserLoggedIn, getUserData } from '@utils';
import { initialAbility } from './initialAbility'
import { Permissions } from "@src/enums/permissions"

export const existingAbility = () => {
    const abilities = initialAbility
    
    if (isUserLoggedIn()) {        
        const account = getUserData();
        
        const claims = account.idTokenClaims as { groups: string[] } | undefined;
        if (claims?.groups && claims.groups.length > 0) {
            claims.groups.forEach(groupId => {
                abilities.push({ 
                    action: Permissions.FullControl, 
                    subject: groupId 
                });
            });            
        }
    }

    return abilities
};

export default new Ability(existingAbility())
