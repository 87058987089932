import { AbilityBuilder, Ability } from "@casl/ability";
import { graphRequest, msalInstance } from "@src/utility/auth/authProvider";
import { Permissions } from "@src/enums/permissions"
import axios from "axios";
import { existingAbility } from "./ability";

const defineAbilitiesForUser = async () => {
  const { can, build } = new AbilityBuilder(Ability);
  
  existingAbility().forEach(existingAbility => {
    can(existingAbility.action, existingAbility.subject);
  })

  const accounts = msalInstance.getAllAccounts();
  if (accounts && accounts.length > 0) {
    const account = accounts[0];
    const tokenResponse = await msalInstance.acquireTokenSilent({ ...graphRequest, account: account });
    const appRoleReponse = await axios.get(`https://graph.microsoft.com/v1.0/me/appRoleAssignments`, { headers: { Authorization: `Bearer ${tokenResponse.accessToken}` }})

    appRoleReponse.data.value.forEach(appRole => {
      can(Permissions.FullControl, appRole.resourceId);
    });
  }

  return build();
};

export default defineAbilitiesForUser;
