import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios";

export const getPolicyRoles = createAsyncThunk(
  "site/getPolicyRoles",
  async () => {
    const response = await axios.get("/api/policy/roles")
    return {
      data: response.data.policyRoles
    }
  }
);

export const site = createSlice({
  name: "site",
  initialState: {
    userAppRolesLoaded: false,
    policyRolesLoaded: false,
    policyRoles: {},
  },
  reducers: {
    handleUserAppRolesLoaded:(state, action) => {
      state.userAppRolesLoaded = action.payload;
    },
    handlePolicyRolesLoaded:(state, action) => {
      state.policyRolesLoaded = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPolicyRoles.fulfilled, (state, action) => {
      state.policyRoles = action.payload.data;
      state.policyRolesLoaded = true;
    })
  }
});

export const {
  handleUserAppRolesLoaded,
  handlePolicyRolesLoaded
} = site.actions

export default site.reducer