import React, { useContext, useEffect, useState } from 'react';

import initalAbility from 'configs/acl/ability';
import defineAbilitiesForUser from 'configs/acl/defineAbilitiesForUser';
import { AbilityContext } from './Can';
import { useDispatch } from 'react-redux';
import { handleUserAppRolesLoaded } from '@src/redux/site';

export const AbilityProvider = ({ children }) => {
  // ** Store Vars
  const dispatch = useDispatch()

  const [ability, setAbility] = useState(initalAbility);

  useEffect(() => {
    const fetchAbilities = async () => {
      const userAbility = await defineAbilitiesForUser();
      setAbility(userAbility);
      
      setTimeout(() => {
        dispatch(handleUserAppRolesLoaded(true));   
      }, 1);   
    };

    fetchAbilities();
  }, []);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export const useAbility = () => useContext(AbilityContext);
