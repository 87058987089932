// ** React Imports
import React, { Suspense, lazy } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

// ** Redux Imports
import { store } from "./redux/store"
import { Provider } from "react-redux"

// ** Intl, CASL & ThemeColors Context
import { AbilityProvider } from "./utility/context/AbilityContext"
import { ThemeContext } from "./utility/context/ThemeColors"

// ** ThemeConfig
import themeConfig from "./configs/themeConfig"

// ** Toast
import { Toaster, ToastPosition } from "react-hot-toast"

// ** Spinner (Splash Screen)
import Spinner from '@src/components/Spinner'

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Query
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** Service Worker
import * as serviceWorker from "./serviceWorker"

import { IntlProviderWrapper } from "./utility/translation/Internationalization"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

const container = document.getElementById("root")
const root = createRoot(container)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    }
  }
});

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Spinner />}>
          <AbilityProvider>
            <ThemeContext>
              <IntlProviderWrapper>
                <LazyApp />
                <Toaster
                  position={themeConfig.layout.toastPosition as ToastPosition}
                  toastOptions={{ 
                    className: "react-hot-toast",
                    duration: 5000,
                    error: {
                      style: {
                        background: '#363636',
                        color: '#fff',
                      },
                    }
                  }}
                />
              </IntlProviderWrapper>
            </ThemeContext>
          </AbilityProvider>
        </Suspense>
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
