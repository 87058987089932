import { Roles } from "@src/enums/roles"
import { Permissions } from "@src/enums/permissions"

// ** Initial user ability
export const initialAbility = [
  {
    action: Permissions.FullControl as string,
    subject: Roles.Authenticated as string
  }
]

export const _ = undefined
