// ** React Imports
import { Navigate } from "react-router-dom"
import { useContext, Suspense } from "react"
import { isUserLoggedIn } from "@src/utility/Utils"

// ** Context Imports
import { AbilityContext } from "@src/utility/context/Can"
import { Roles } from "@src/enums/roles"
import { Permissions } from "@src/enums/permissions"
import { useSelector } from "react-redux"
import { Policies } from "@src/enums/policies"

const PrivateRoute = ({ children, route }) => {  
  // ** Store Vars
  const userAppRolesLoaded = useSelector((state) => state.site.userAppRolesLoaded)
  const policyRolesLoaded = useSelector((state) => state.site.policyRolesLoaded)
  const policyRoles = useSelector((state) => state.site.policyRoles)

  // ** Hooks & Vars
  const ability = useContext(AbilityContext)
  
  const isLoggedIn = isUserLoggedIn()

  if (route) {
    let action = null
    let resource = null
    let policy = null
    let restrictedRoute = false

    if (route.meta) {
      action = route.meta.action
      resource = route.meta.resource
      policy = route.meta.policy
      restrictedRoute = route.meta.restricted
    }
    if (!isLoggedIn) {
      return <Navigate to="/login" />
    }
    if (isLoggedIn && restrictedRoute) {
      return <Navigate to="/" />
    }
    if (isLoggedIn && userAppRolesLoaded && policyRolesLoaded) {
      if (action && resource) {
        // Check using defined actions & resources 
        if (!ability.can(action || Permissions.FullControl, resource || Roles.Authenticated)) {
          return <Navigate to="/unauthorised" replace />
        }
      } else if (policy) {        
        // Check using policy roles 
        // User must be in at least one of the policy roles
        // Or ignore if there are no policy roles
        const roles = policyRoles[Policies[policy]]
        const allFailed = roles.every(role => !ability.can(Permissions.FullControl, role))

        if (allFailed && roles.length > 0) {
          return <Navigate to="/unauthorised" replace />
        }
      }
    }
  }  

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
