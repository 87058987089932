
// ** React Imports
import React, { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

import messageEn from './data/en.json';
import messageDe from './data/de.json';
import messageEngb from './data/uk.json';
import messageEs from './data/es.json';
import messagePt from './data/pt.json';

const menuMessages = {
    en: {...messageEn },
    uk: {...messageEngb },
    de: {...messageDe },
    es: {...messageEs },
    pt: {...messagePt },
    
}

const Context = createContext<any>({});

const IntlProviderWrapper = ({ children }) => {
    const [locale, setLocale] = useState('en')
    const [messages, setMessages] = useState(menuMessages['en'])
 
    const handleError = (error) => {
      if (error.code !== 'MISSING_TRANSLATION') // Supress missing translation error
        console.error(error);
    }

 // ** Switches Language
 const switchLanguage = lang => {
 
    setLocale(lang)
    setMessages(menuMessages[lang])
  }
    return (
        <Context.Provider value={{ locale, switchLanguage }}>
        <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en' onError={handleError} >
          {children}
        </IntlProvider>
      </Context.Provider>
    ) 
}


export { IntlProviderWrapper, Context as IntlContext }