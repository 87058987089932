import Avatar from "@src/components/compatibility/Avatar";
import { MenuItem } from "@src/models/menuModels";
// ** Third Party Components
import classnames from "classnames"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ContentFooter = props => {  
  const { windowWidth } = props as { windowWidth:number};
  const menuData: MenuItem[] = useSelector((state: any) => state.menu.menuData);
  const location = useLocation();
  
  const [showFooterLink, setShowFooterLink] = useState<boolean>(false);
  const [footerLinkText, setFooterLinkText] = useState<string>();
  const [footerLinkUrl, setFooterLinkUrl] = useState<string>();

  useEffect(() => {
    if (menuData && menuData.length > 0) {
      let currentMenuItem = menuData.find(x => x.navLink === location.pathname || (x.children && x.children.some(y => y.navLink === location.pathname)));
      let hasFooterLink = currentMenuItem && currentMenuItem.footerLinkText?.length > 0;

      if (currentMenuItem && currentMenuItem.children && !hasFooterLink) {
        currentMenuItem = currentMenuItem.children.find(x => x.navLink === location.pathname);      
        hasFooterLink = currentMenuItem && currentMenuItem.footerLinkText?.length > 0;
      }

      if (hasFooterLink) {
        setFooterLinkText(currentMenuItem!.footerLinkText);
        setFooterLinkUrl(currentMenuItem!.footerLinkUrl);
        setShowFooterLink(true);
      } else {
        setFooterLinkText(undefined);
        setFooterLinkUrl(undefined);
        setShowFooterLink(false);
      }
    }
  }, [menuData, location]);

  return(
      <>
      <p className='clearfix mb-0'>
        <span className={classnames(`float-md-left d-block d-md-inline-block mt-1`, {
          "text-left": windowWidth < 760,
          "text-center": windowWidth >= 760
        })}>
          {showFooterLink 
            ? <>
                <a href={footerLinkUrl} target="_blank">
                  <Avatar color={"primary" as any} content="i" initials={true} className="me-25 footer-icon" />
                </a>
                <a href={footerLinkUrl} target="_blank" className="footer-info">
                  {footerLinkText}
                </a>
              </>
            : <span>&nbsp;</span>}
        </span>
      </p>
      </>
  )
}

export default ContentFooter;