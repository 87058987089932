import { Card } from "reactstrap"
import VerticalNavMenuItems from "./VerticalNavMenuItems"
import { useEffect } from "react"
const VerticalNavMenuHoverLink = ({
   items,
   groupOpen,
   menuHover,
   activeItem,
   groupActive,
   setGroupOpen,
   menuCollapsed,
   setGroupActive,
   currentActiveGroup,
   setCurrentActiveGroup,
   isGroup,
   isLabelOpen,
   item,
   setCurrentOpenGroup,
   currentOpenGroup,
   ...rest

}) => {

const onMouseLeave = () => {
  setCurrentOpenGroup("")
}

useEffect(() => {
 if (isLabelOpen && currentOpenGroup !== "") {
    setCurrentOpenGroup("")
 }
}, [])

  return (
   <>
    {
     isGroup ? <div onMouseLeave={onMouseLeave}  className="hover-navigation-container"><Card  className="hover-navigation">
     <ul className="menu-content">
     <VerticalNavMenuItems
                    {...rest}
                    items={items}
                    groupActive={groupActive}
                    setGroupActive={setGroupActive}
                    currentActiveGroup={currentActiveGroup}
                    setCurrentActiveGroup={setCurrentActiveGroup}
                    groupOpen={groupOpen}
                    setGroupOpen={setGroupOpen}
                    parentItem={items}
                    menuCollapsed={menuCollapsed}
                    menuHover={menuHover}
                    activeItem={activeItem}
                    isSlideNav={true}
                    />
        </ul> </Card> </div> : isLabelOpen ? <div className="nav-hoverlabel"> <p>{item.title}</p> </div> : <></>
     }
   </>
  ) 
    
}

export default VerticalNavMenuHoverLink