import { createSlice } from "@reduxjs/toolkit"

export const menu = createSlice({
  name: "menu",
  initialState: {
    menuData: undefined,
  },
  reducers: {
    handleMenuData:(state, action) => {
      state.menuData = action.payload;
    }
  }
});

export const {
  handleMenuData
} = menu.actions

export default menu.reducer