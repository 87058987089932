import AvatarBase from "@components/avatar"
import { ReactElement } from 'react'

/** @param {any} */
const Avatar = ({   
  id = undefined,
  img = ReactElement, 
  imgHeight = '', 
  imgWidth = '', 
  size = undefined,
  status =  undefined,
  color = undefined, 
  className = '', 
  icon = ReactElement,
  badgeUp = false, 
  badgeText = '',
  badgeColor = undefined,
  initials = false,
  tag = ReactElement,
  content = '',
  contentStyles = undefined
}) => {

    return (
      <>
        <AvatarBase
          id={id}
          img={img}
          imgHeight={imgHeight}
          imgWidth={imgWidth}
          size={size}
          status={status}
          color={color} 
          className={className}
          icon={icon}
          badgeText={badgeText}
          badgeUp={badgeUp}
          badgeColor={badgeColor}
          initials={initials}
          tag={tag}
          content={content}
          contentStyles={contentStyles}
        /> 
      </>
    )
}

export default Avatar