import React, { useState, useContext, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import { Save, Settings, X } from "react-feather";
import userpreference, { handleLanguage, handleSalesRegion } from "../../redux/userpreference";
import { useDispatch, useSelector } from "react-redux";
import { IntlContext } from "../../utility/translation/Internationalization";
import { msalInstance } from "@src/utility/auth/authProvider";
import axios from "axios";
import { getCountryCode, Countries, getUserCountry, getCountryName} from "../../pages/ServicePortfolio/Utilities/CountryCode";
import { PortfolioFilterTypes } from "@src/enums/portfolioFilterTypes";
import { SupportedLanguage } from "../../utility/Utils";
interface userPreferenceModalProp {
  isOpen: boolean;
  handelUserPreferenceModalOpen: () => void;
}

const UserPreferenceModal = (props: userPreferenceModalProp) => {
  const dispatch = useDispatch();
  const store = useSelector((state: any) => state.userpreference);
 
  const [userPreparedlanguage, setuserPreparedlanguage] = useState(store.language);
  const [userSalesRegion, setUserSalesRegion] = useState<{
    value: string;
    label: string;
  }>({ value: store.userSalesRegion.value, label: store.userSalesRegion.label });
  const intlContext = useContext(IntlContext);
 
  const handleLanguageChange = (language: string) => {
    setuserPreparedlanguage(language);
  };

  const handleSalesRegionChange = (value: string, ) => {
    setUserSalesRegion({value: getCountryCode(value), label: value});
  };

  const handleSave = () => {
    let value: UserPreferences = {language :'', salesRegion:''};
 
     value.language = userPreparedlanguage;
     value.salesRegion = userSalesRegion.value

    axios                         
    .post<UserPreferencesResponse>(`/api/user/preferences`, value)
    .then((response) => {
    }).finally(() => {
   
      dispatch(handleLanguage("en"));
      dispatch(handleSalesRegion({value:getCountryCode(userSalesRegion.value),label:getCountryName(userSalesRegion.value)}));
      intlContext.switchLanguage(userPreparedlanguage);
      props.handelUserPreferenceModalOpen()
    });

  };

  const getGraph = async (id:string| undefined) => {
    await axios
      .get(`https://graph.microsoft.com/v1.0/users/${id}?$select=displayName,userPrincipalName,mail,country`)
      .then((res) => {
        dispatch(handleSalesRegion(
          res.data.country == undefined || res.data.country == ''
          ? {value: 'GO', label: 'GLOBAL'}
          : {value: getUserCountry(res.data.country)?.Code, label: getUserCountry(res.data.country)?.name}  
      ))
      });
  };

  useEffect(() => {
 
    const accounts = msalInstance.getAllAccounts();
    const account = accounts[0];
 
    if (!accounts || accounts.length === 0) {
      dispatch(handleSalesRegion({code: PortfolioFilterTypes.All, name: PortfolioFilterTypes.All}));   
    } else {
   
      let salesRegion:string = '';
      axios
      .get<UserPreferencesResponse>(`/api/user/preferences`)
      .then((response) => {
        salesRegion = response.data.userPreferences.salesRegion;
        // setuserPreparedlanguage(response.data.userPreferences.language);
        setuserPreparedlanguage("en")
      }).finally(() => {
        if (salesRegion == "") {
          getGraph(account?.idTokenClaims?.oid);
        } else {
          setUserSalesRegion({value:getCountryCode(salesRegion),label:getCountryName(salesRegion)});
          
          if (window.localStorage.getItem("sp_Filter") == "" || 
              window.localStorage.getItem("sp_Filter") == null){
            dispatch(handleSalesRegion({value:getCountryCode(salesRegion),label:getCountryName(salesRegion)}));
          }
        }
      });
    }
 
  }, []);

  return (
    <Modal
      isOpen={props.isOpen}
      className="sidebar-lg"
      contentClassName="p-0 overflow-hidden"
      modalClassName="modal-slide-in event-sidebar"
      toggle={props.handelUserPreferenceModalOpen}
    >
      <ModalHeader className="mb-1">
        <Settings size={20} className="me-1" /> User Preferences
      </ModalHeader>
      <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
        <Row className="mb-1">
          <Col>
            <div>
              <strong>Language Preference</strong>
            </div>
            <Input
              type="select"
              value={userPreparedlanguage}
              onChange={(e) => handleLanguageChange(e.currentTarget.value)}
            >
             
             {
              SupportedLanguage.map(e => 
                (
                  <option key={e.code} value={e.code}>{e.name}</option>
                )  
              )
             }
            </Input>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col>
            <div>
              <strong>Default Sales Region</strong>
            </div>
            <Input
              type="select"
              value={userSalesRegion.label}
              onChange={(e) => handleSalesRegionChange(e.currentTarget.value)}
            >
              {Countries.filter(e => e.isSalesRegion).sort((a, b) => a.name.localeCompare(b.name)).map(
                (e) => (
                  <option key={e.Code} id={e.Code}>
                    {e.name}
                  </option>
                )
              )}
            </Input>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex mb-1">
              <Button
                className="me-1 w-100"
                type="submit"
                color="primary"
                onClick={props.handelUserPreferenceModalOpen}
              >
                <X size={14} />
                <span className="align-middle ms-50">Cancel</span>
              </Button>
              
              <Button
                className="me-1 w-100"
                type="submit"
                color="primary"
                onClick={handleSave}
              >
                <Save size={14} />
                <span className="align-middle ms-50">Save</span>
              </Button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default UserPreferenceModal;
