// ** Logo
import logo from 'assets/images/logo/main-logo.png'
import { FC } from 'react'

interface SpinnerProps {
  fullScreen?: boolean
}

const Spinner: FC<SpinnerProps> = ({ fullScreen = false }) => {
  return (
    <div className={`fallback-spinner${fullScreen ? ' vh-100' : ''}`}>
      <img className='fallback-logo' src={logo} alt='Loading...' />
    </div>
  )
}

export default Spinner
