export enum PortfolioFilterTypes {
  Type = 'Type',
  Category = 'Category',
  BusinessModel ="BusinessModel",
  VendorName = "VendorName",
  SalesRegion = "SalesRegion",
  DFPScore= "DFPScore",
  DeliveryHub = "deliveryHub",
  TextSearch = "TextSearch",
  All = "All"
}