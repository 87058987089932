// ** React Imports
import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/HorizontalLayout"

// ** Menu Items Array
import axios from "axios"
import { handleMenuData } from "@src/redux/menu"
import { useDispatch } from 'react-redux';

const HorizontalLayout = (props) => {
  const [menuData, setMenuData] = useState([]);
  const dispatch = useDispatch();

  // ** For ServerSide navigation
  useEffect(() => {
    axios.get(`/api/menu/structure`)
      .then(response => {
        setMenuData(response.data.menuStructure);
        dispatch(handleMenuData(response.data.menuStructure));   
      });
  }, [])

  return (
    <Layout menuData={menuData} {...props}>
      <Outlet />
    </Layout>
  )
}

export default HorizontalLayout
