export const Countries = [
  {
    name:"Andean",
    Code: "AN",
    userCountry:["BO","Bolivia","CO","Colombia","EC","Ecuador","PE","Peru"],
    isSalesRegion:true
  },
  {
    name: "Australia",
    Code: "AU",
    userCountry:["AU","Australia"],
    isSalesRegion:true
  },
  {
    name: "Brazil",
    Code: "BR",
    userCountry:["BR","Brazil"],
    isSalesRegion:true
  },
  {
    name: "Bolivia",
    Code: "BO",
    userCountry:["Not Applicable"],
    isSalesRegion:false
  },
  {
    name: "Colombia",
    Code: "CO",
    userCountry:["Not Applicable"],
    isSalesRegion:false
  },
  {
    name: "Channel Islands",
    Code: "CI",
    userCountry:["CI","Channel Islands","X1"],
    isSalesRegion:true
  },
  {
    name: "China",
    Code: "CH",
    userCountry:["CH","China"],
    isSalesRegion:true
  },
  {
    name: "Chile",
    Code: "CL",
    userCountry:["Not Applicable"],
    isSalesRegion:false
  },
  {
    name: "Ecuador",
    Code: "EC",
    userCountry:["Not Applicable"],
    isSalesRegion:false
  },
  {
    name: "Germany",
    Code: "DE",
    userCountry:["DE","Germany"],
    isSalesRegion:true
  },
  {
    name: "GLOBAL",
    Code: "GO",
    userCountry:["GO","GLOBAL"],
    isSalesRegion:true
  },
  {
    name: "Hong Kong",
    Code: "HK",
    userCountry:["HK","Hong Kong"],
    isSalesRegion:true
  },
  {
    name: "Indonesia",
    Code: "ID",
    userCountry:["ID","Indonesia"],
    isSalesRegion:true
  },
  {
    name: "Ireland",
    Code: "IE",
    userCountry:["IE","Ireland"],
    isSalesRegion:true
  },
  {
    name: "Jersey",
    Code: "JE",
    userCountry:["JE","Jersey"],
    isSalesRegion:false
  },
  {
    name: "Malaysia",
    Code: "MY",
    userCountry:["MY","Malaysia"],
    isSalesRegion:true
  },
  {
    name: "Mexico",
    Code: "MX",
    userCountry:["MX","Mexico"],
    isSalesRegion:true
  },
  {
    name: "Netherlands",
    Code: "NL",
    userCountry:["NL","Netherlands"],
    isSalesRegion:true
  },
  {
    name: "Portugal",
    Code: "PT",
    userCountry:["PT","Portugal"],
    isSalesRegion:true
  },
  {
    name: "Paraguay",
    Code: "PY",
    userCountry:["Not Applicable"],
    isSalesRegion:false
  },
  {
    name: "Peru",
    Code: "PE",
    userCountry:["Not Applicable"],
    isSalesRegion:false
  },
  {
    name: "Singapore",
    Code: "SG",
    userCountry:["SG","Singapore"],
    isSalesRegion:true
  },
  {
    name: "South Africa",
    Code: "ZA",
    userCountry:["ZA","South Africa"],
    isSalesRegion:true
  },
  {
    name: "Southern Cone",
    Code: "AR",
    userCountry:["AR","Argentina","CL","Chile","PY","Paraguay","UY","Uruguay"],
    isSalesRegion:true
  },
  {
    name: "Spain",
    Code: "ES",
    userCountry:["ES","Spain"],
    isSalesRegion:true
  },
  {
    name: "Taiwan",
    Code: "TW",
    userCountry:["TW","Taiwan"],
    isSalesRegion:true
  },
  {
    name: "United Kingdom",
    Code: "UK",
    userCountry:["UK","United Kingdom","GB","Great Britain"],
    isSalesRegion:true
  },
  {
    name: "United States",
    Code: "US",
    userCountry:["US","United States"],
    isSalesRegion:true
  },
  {
    name: "Uruguay",
    Code: "UY",
    userCountry:["Not Applicable"],
    isSalesRegion:false
  },
];
 
export const getCountryCode = (countryName: string) => {
  const code = Countries.filter(
    (e) => e.name.toLowerCase() == countryName.toLowerCase()
  );
  if (code.length > 0) {
    return code[0].Code;
  } else {
    return countryName;
  }
};

export const getCountryName = (countryCode: string) => {
 
  const code = Countries.filter(
    (e) => e.Code.toLowerCase() == countryCode?.toLowerCase()
  );
  if (code.length > 0) {
    return code[0].name;
  } else {
    return countryCode;
  }
};

export const getUserCountry = (val: string) => {

  let country = Countries.filter(e => e.userCountry.indexOf(val) > -1);
  if (country.length > 0){
    return country[0]
  }else {
    return null
  }
  
}