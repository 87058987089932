import { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { handleMenuCollapsed } from '@store/layout'
import UserDropdown from './UserDropdown'

// ** Third Party Components
import { Sun, Moon, Menu, Disc, Circle, Code, AlignJustify } from 'react-feather'
import { NavItem, NavLink, Badge, UncontrolledTooltip } from 'reactstrap'

const Navbar = props => {
 
  const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
  const { menuCollapsed, setMenuCollapsed, windowWidth, setMenuVisibility } = props
  
  const EnvironmentTag = () => {
    if (appEnvironment != 'Production') {
      return (
        <Badge color='info' className='badge-glow ms-3'>
          <Code size={12} className='align-middle me-25' />
          { appEnvironment }
        </Badge>
      );
    }

    return (<></>);
  }
  

 
  return (
    <Fragment>
      <div className='d-flex align-items-center d-inline-block w-100 nav-header' >
        <div className='d-flex w-100 justify-content-start'>
          <ul className='navbar-nav d-xl-none d-flex align-items-center'>
          <NavItem className='mobile-menu mr-auto'>
            <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => setMenuVisibility(true)}>
              <Menu className='ficon' />
            </NavLink>
          </NavItem>
        </ul>
          <div className='d-flex align-items-center'>
            <h1 className="navbar-heading">Digital Fabric Platform</h1>
            <EnvironmentTag />
          </div>
        </div>
        <div className='d-flex w-100 justify-content-end'>
          <ul className='nav navbar-nav align-items-center ms-auto'>
            <UserDropdown />
          </ul>
        </div>
      
      </div>
    </Fragment>
  
  )
}
export default Navbar