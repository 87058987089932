import { createSlice } from "@reduxjs/toolkit"

 

export const userpreference = createSlice({
    name: "userpreference",
    initialState: {
        language:"en",
        userSalesRegion: {value: 'All', label: 'All'}
    },
    reducers: {
        handleLanguage:(state, action) => {
            state.language = action.payload;
        },
        handleSalesRegion:(state,action) => {
            state.userSalesRegion = action.payload
            window.localStorage.setItem("salesRegion", JSON.stringify(action.payload));
        }
    }
});

export const {
    handleLanguage,
    handleSalesRegion
  } = userpreference.actions

  export default userpreference.reducer