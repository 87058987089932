import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const configuration: Configuration = {
  auth: {
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID!,
    navigateToLoginRequestUrl: false,
    redirectUri: window.location.origin + process.env.REACT_APP_MSAL_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const msalInstance = new PublicClientApplication(configuration);

export const loginRequest = {
  scopes: JSON.parse(process.env.REACT_APP_MSAL_SCOPE!)
};

export const graphRequest = {
  scopes: JSON.parse(process.env.REACT_APP_MSAL_GRAPH_SCOPE!)
};
 