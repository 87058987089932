// ** React Imports
import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout"
import Navbar from "@src/layouts/components/Navbar"
import Footer from "@src/layouts/components/Footer"
import ContentFooter from "@src/layouts/components/ContentFooter"
// ** Menu Items Array
//import navigation from "@src/navigation"
import axios from "axios"
import { useDispatch } from "react-redux"
import { handleMenuData } from "@src/redux/menu"

const VerticalLayout = (props) => {
  const [menuData, setMenuData] = useState([]);
  const dispatch = useDispatch();

  // ** For ServerSide navigation
  useEffect(() => {
    axios.get(`/api/menu`)
      .then(response => {
        setMenuData(response.data.menuStructure);
        dispatch(handleMenuData(response.data.menuStructure)); 
      });  
  }, [])

  const [menuCollapsed, setMenuCollapsed] = useState(window.localStorage.getItem("menuCollapsed") == 'true');

  useEffect(() => {
    window.localStorage.setItem("menuCollapsed", JSON.stringify(menuCollapsed));
  }, [menuCollapsed])

  return (
    <Layout 
      menuData={menuData}
      menuCollapsed={menuCollapsed}
      setMenuCollapsed={setMenuCollapsed}
      navbar={props => <Navbar {...props} menuCollapsed={menuCollapsed} setMenuCollapsed={setMenuCollapsed} />}
      footer={props => <Footer {...props} menuCollapsed={menuCollapsed} setMenuCollapsed={setMenuCollapsed}/>}
      contentfooter={props => <Footer {...props} menuCollapsed={menuCollapsed} setMenuCollapsed={setMenuCollapsed}/>}
      {...props}
    >
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
function setMenuData(menuStructure: any): any {
  throw new Error("Function not implemented.")
}

