// ** Config
import themeConfig from '@configs/themeConfig'
import React from 'react'

const Footer = props => {
 
 const { menuCollapsed } = props as {menuCollapsed: boolean}
  return (
    <>
      {menuCollapsed ? (
        <p className='clearfix mb-0'>
          <span className='float-md-left d-block d-md-inline-block mt-1 text-center'> 
            <span className='footer-copy-icon'>©</span> <br /> 
            {new Date().getFullYear()} 
          </span>
        </p>
        ) : 
      (
        <p className='clearfix mb-0'>
        <span className='float-md-left d-block d-md-inline-block mt-1'>
          Copyright<span className='footer-copy-icon'> © </span> 
          {new Date().getFullYear()}{' '}<br />
        <a href='https://www.logicalis.com/' target='_blank' rel='noopener noreferrer'>
          {themeConfig.app.appName},
        </a>
        <span className='d-none d-sm-inline-block'>All rights Reserved</span>
      </span>
      </p>
      )

      }
    </>
 
  )
}

export default Footer
