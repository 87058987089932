const scriptId = "jiraSupportCenter";

const LoadSupportCenter = (callback) => {
  const existingScript = document.getElementById(scriptId);
  if (!existingScript) {
    const script = document.createElement('script');
    script.id = scriptId;
    script.setAttribute('data-jsd-embedded', '');
    script.setAttribute('data-key', 'b70f179a-a764-4d4f-a70c-eb4b6d241dc7');
    script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
    script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
    document.body.appendChild(script);
    script.onload = () => { 
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export default LoadSupportCenter;