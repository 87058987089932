/* eslint-disable implicit-arrow-linebreak */
// ** React Imports
import { useContext } from "react"
import { AbilityContext } from "@src/utility/context/Can"
import { Permissions } from "@src/enums/permissions"
import { Roles } from "@src/enums/roles"

/**
 * Return which component to render based on it's data/context
 * @param {Object} item nav menu item
 */
export const resolveVerticalNavMenuItemComponent = (item) => {
  if (item.header) return "VerticalNavMenuSectionHeader"
  if (item.children) return "VerticalNavMenuGroup"
  return "VerticalNavMenuLink"
}

/**
 * Return which component to render based on it's data/context
 * @param {Object} item nav menu item
 */
export const resolveHorizontalNavMenuItemComponent = (item) => {
  if (item.children) return "HorizontalNavMenuGroup"
  return "HorizontalNavMenuLink"
}

/**
 * Check if nav-link is active
 * @param {Object} link nav-link object
 */
export const isNavLinkActive = (link, currentURL, routerProps) => {
  return (
    currentURL === link ||
    (routerProps &&
      routerProps.meta &&
      routerProps.meta.navLink &&
      routerProps.meta.navLink === link) ||
    (routerProps && 
      routerProps.baseUrl &&
      (routerProps.baseUrl === currentURL || currentURL.includes(routerProps.baseUrl)))
  )
  // return currentURL === link
}

/**
 * Check if the given item has the given url
 * in one of its children
 *
 * @param item
 * @param activeItem
 */
export const hasActiveChild = (item, currentUrl) => {
  const { children } = item

  if (!children) {
    return false
  }

  for (const child of children) {
    if (child.children) {
      if (hasActiveChild(child, currentUrl)) {
        return true
      }
    }

    // Check if the child has a link and is active
    if (child && child.navLink && currentUrl) {
      let isMatch = child.navLink === currentUrl || currentUrl.includes(child.navLink)
      
      if (!isMatch && child.baseUrl) {
        isMatch = child.baseUrl === currentUrl || currentUrl.includes(child.baseUrl)
      }
      
      if (isMatch) {
        return true
      }
    } 
  }

  return false
}

/**
 * Check if this is a children
 * of the given item
 *
 * @param children
 * @param openGroup
 * @param currentActiveGroup
 */
export const removeChildren = (children, openGroup, currentActiveGroup) => {
  children.forEach((child) => {
    if (!currentActiveGroup.includes(child.id)) {
      const index = openGroup.indexOf(child.id)
      if (index > -1) openGroup.splice(index, 1)
      if (child.children) removeChildren(child.children, openGroup, currentActiveGroup)
    }
  })
}

const checkForVisibleChild = (arr, ability) => {
  return arr.some((item) => {
    if (item.children) {
      return checkForVisibleChild(item.children, ability)
    } else {
      // Only show menu items if user is authenticated
      if (!ability.can(Permissions.FullControl, Roles.Authenticated)) return false

      if (!(item.roles) || item.roles.length === 0) return true

      for (let index = 0; index < item.roles.length; index++) {
        return ability.can(Permissions.FullControl, item.roles[index])
      }   
    }
  })
}

export const canViewMenuGroup = (item) => {
  const ability = useContext(AbilityContext)

  // Only show menu items if user is authenticated
  if (!ability.can(Permissions.FullControl, Roles.Authenticated)) return false

  // ! This same logic is used in canViewHorizontalNavMenuGroup and canViewHorizontalNavMenuHeaderGroup. So make sure to update logic in them as well
  const hasAnyVisibleChild = item.children && checkForVisibleChild(item.children, ability)

  // ** If roles is defined in item => Return based on children visibility (Hide group if no child is visible)
  // ** Else check for ability using provided roles along with checking if has any visible child
  if (!(item.roles) || item.roles.length === 0) return hasAnyVisibleChild

  // If the user is a member of any of the roles then show menu item
  for (let index = 0; index < item.roles.length; index++) {
    if (ability.can(Permissions.FullControl, item.roles[index]) && hasAnyVisibleChild) {
      return true
    }
  }
  return false
}

export const canViewMenuItem = (item) => {
  const ability = useContext(AbilityContext)

  // Only show menu items if user is authenticated
  if (!ability.can(Permissions.FullControl, Roles.Authenticated)) return false

  // If they aren't any defined roles then show menu item
  if (!(item.roles) || item.roles.length === 0) return true

  // If the user is a member of any of the roles then show menu item
  for (let index = 0; index < item.roles.length; index++) {
    if (ability.can(Permissions.FullControl, item.roles[index])) {
      return true
    }
  }
  return false
}
